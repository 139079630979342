import * as React from "react"
import { Link } from "gatsby"
import gatsbyConfig from "../../../gatsby-config"
import { SMALL_INQUIRY_BUTTON_TEXT } from 'settings'

/**
 * 共通ボタン（小）
 * 使用箇所：ヘッダー右上（PC）
 **/ 
export const InquiryButtonSmall = () => {
  return (
    <Link
      className="inquiry-button--s"
      to={`${ gatsbyConfig.siteMetadata.siteUrl }/inquiry`}>
      { SMALL_INQUIRY_BUTTON_TEXT }
    </Link>
  )
}