import * as React from "react"
import { Link } from "gatsby"
import { FORM } from 'settings'

/**
 * SPボタン
 * 使用箇所：固定フッター（SP）
 **/ 
export const InquiryButtonToForm = () => {
  return (
    <Link
      className='inquiry__button'
      to={ FORM.URL }>フォーム
      { FORM.TEXT } 
    </Link>
  )
}