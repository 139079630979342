import * as React from "react"

import { WAVY_LINE_COLOR } from 'consts'

import NavyLine from 'images/wavy-line--navy.webp'
import WhiteLine from 'images/wavy-line--white.webp'

const SectionHeader = (props) => {
  return (
    <>
      <h3 className="section-header">{ props.text }</h3>
      {
        props.lineColor === WAVY_LINE_COLOR.NAVY ?
        <img src={ NavyLine } alt="under line" className="section-header__under-line" /> :
        <img src={ WhiteLine } alt="under line" className="section-header__under-line" />
      }
    </>
  )
}
export default SectionHeader