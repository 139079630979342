import * as React from "react"
import { InquiryButtonSmall } from 'components/atoms/inquiry-button-small'
import { PhoneAnchor } from 'components/atoms/phone-anchor'
import Img from 'images/logo--service.webp'
import { useLocation } from '@reach/router'
import gatsbyConfig from '../../../gatsby-config'

export const useLimitedHostname = () => {

  const location = useLocation()

  if ( location.hostname === 'waaf-lp-prd.web.app' || location.hostname === 'waaf-lp-prd.firebaseapp.com' ) {
      window.location.href = gatsbyConfig.siteMetadata.siteUrl
  }
}

export const PcHeader = () => {

  useLimitedHostname()

  const section = 'pc-header'
  return (
    <header className={section}>
      <nav className={`${section}__items-box`}>
        <h1 className={`${section}__logo-box`}>
          <a href="/">
            <img src={ Img } alt="logo" className={`${section}__logo`} />
            <span className={`${section}__logo-text`}>水産業界の人材採用支援サービス</span>
          </a>
        </h1>
        <div className={`${section}__inquiry-box`}>
          <span className={`${section}__phone-number`}><span className="text--small">TEL</span><PhoneAnchor /></span>
          <span className={`${section}__button`}>
            <InquiryButtonSmall />
          </span>
        </div>
      </nav>
    </header>
  )
}

export const SpHeader = ({ location }) => {

  useLimitedHostname(location)

  const section = 'sp-header'
  return (
    <header className={section}>
      <nav>
        <h1 className={`${section}__logo-box`}>
          <img src={ Img } alt="logo" className={`${section}__logo`} />
          <span className={`${section}__logo-text`}>水産業界の人材採用支援サービス</span>
        </h1>
      </nav>
    </header>
  )
}
