import * as React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      <ul className="footer__text-list">
        <li>
          <a href="https://lighthouse-frontier.tech">WaaF 運営会社 Lighthouse</a><a href='/privacy' className="footer__privacy">個人情報の取扱いについて</a>
        </li>
        <li>
          <a href="https://lighthouse-frontier.tech">Copyright 2021 Lighthouse, inc.</a>
        </li>
      </ul>
    </footer>
  )
}
export default Footer