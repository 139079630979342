import * as React from "react"
import gatsbyConfig from "../../gatsby-config"
import { Helmet } from "react-helmet"
import OgImg from 'images/image--top1.webp'
import { WAVY_LINE_COLOR } from 'consts'
import { InquiryButtonToForm } from 'components/atoms/inquiry-button-to-form'
import SectionHeader from 'components/molecules/section-header'
import { FORM } from 'settings'

// sections
import { PcHeader, SpHeader } from 'components/organisms/header'
import Footer from 'components/organisms/footer'

import 'styles/index.scss'

const Inquiry = () => {

  setTimeout (() => {
    window.location.href = FORM.URL
  }, 1000)

  const section = 'inquiry'
  const title = 'WaaF | お問い合わせ'
  
  return (
    <main className="theme">
      <Helmet>
        <html lang={ gatsbyConfig.siteMetadata.lang } />
        <title>{ title }</title>
        <meta name="description" content={ gatsbyConfig.siteMetadata.description } />
        <meta name="keywords" content={ gatsbyConfig.siteMetadata.keywords } />
        <meta name="image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:title" content={ title } />
        <meta property="og:site_name" content={ title } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ gatsbyConfig.siteMetadata.siteUrl } />
        <meta property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:description" content={ gatsbyConfig.siteMetadata.description } />
        <meta property="og:locale" content="ja_JP" />
        <link rel="shortcut icon" href="../images/icon.png" type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href="../images/icon.png" />
        {/* <script src="https://sdk.form.run/js/v2/embed.js"></script> */}{/* formrunをページ内に埋め込む時に使用する */}
      </Helmet>

      <PcHeader />
      <SpHeader />

      <section className={section}>
        <SectionHeader
          text='お問い合わせ'
          lineColor={ WAVY_LINE_COLOR.NAVY }
        />
        <p>
          問い合わせフォームに遷移しています。<br/>
          自動的にページが切り替わらない場合は、以下のボタンをクリックしてください。
        </p>
        <div className={`${section}__button-box`}>
          <InquiryButtonToForm />
        </div>

        {/* formrunをページ内に埋め込む時に使用する */}
        {/* <div
          className="formrun-embed"
          data-formrun-form="@waaf-form"
          data-formrun-redirect="true">
        </div> */}

      </section>
      
      <Footer />
    </main>
  )
}

export default Inquiry
