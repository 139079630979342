/**
 * 【設定】
 * 共通の設定や部品、変更可能性の高い数字やテキストについて設定を行います。
 */


/* firebase の設定情報 */
export const FIREBASE_CONFIG = {
  PRD: {
    apiKey: "AIzaSyBl4IoPiBKeyLH1PWBBn5HPRa8743CwjNc",
    authDomain: "waaf.jp",
    projectId: "waaf-lp-prd",
    storageBucket: "waaf-lp-prd.appspot.com",
    messagingSenderId: "498774522149",
    appId: "1:498774522149:web:5c68550c879fd5eab982cc"
  },
  DEV: {
    apiKey: "AIzaSyDvYWNCqeciEcFesy_AbTUXPpxUVpQh-5M",
    authDomain: "waaf-lp-dev.firebaseapp.com",
    projectId: "waaf-lp-dev",
    storageBucket: "waaf-lp-dev.appspot.com",
    messagingSenderId: "899333537930",
    appId: "1:899333537930:web:7922076bec95903e25ad73"
  }
}


/* 営業時間 */
export const BUSINESS_HOURS = '平日 10:00-18:00'

/* 電話番号 */
export const PHONE_NUMBER = '050-3628-1157'

/* 問い合わせボタン（大サイズ, PC/SP 共通, ページ下部で使用）のラベル */
export const LARGE_INQUIRY_BUTTON_TEXT = '無料相談はこちらから'

/* 問い合わせボタン（中サイズ, PC/SP 共通, ファーストビューで使用）のラベル */
export const MEDIUM_INQUIRY_BUTTON_TEXT = 'まずは無料相談'

/* 問い合わせボタン（小サイズ, PC のみ, ヘッダー右上で使用）のラベル */
export const SMALL_INQUIRY_BUTTON_TEXT = '今すぐ無料相談'

/* 問い合わせボタン（小サイズ, SP のみ, 固定フッター）のラベル */
export const SP_INQUIRY_BUTTON_TEXT = '資料請求'

/* 電話ボタン（小サイズ, SP のみ, 固定フッター）のラベル */
export const SP_CALL_BUTTON_TEXT = '電話'

/* トップページに戻るボタンのラベル */
export const BACK_BUTTON_TEXT = 'トップページに戻る'

/* FORM へ遷移するボタンのラベルと URL */
export const FORM = {
  TEXT: '問い合わせフォームに移動',
  URL: 'https://form.run/@waaf-form'
}

/* LINE ボタンの ラベルと URL */
export const LINE = {
  BUTTON_TEXT: 'ライトハウスを LINE 友達追加する',
  URL: 'https://aura-mico.jp/qr-codes/53811/preview'
}

/* 実績に関する数値 */
export const ACHIEVEMENT_NUMBERS = {
  AREA: 23,
  APPLICANT: 1500,
  NEW_EMPLOYEE: 160
}
